/*@jsxRuntime classic @jsx React.createElement @jsxFrag React.Fragment*/
import {useMDXComponents as _provideComponents} from "@mdx-js/react";
import React from "react";
function _createMdxContent(props) {
  const _components = Object.assign({
    p: "p",
    em: "em",
    a: "a"
  }, _provideComponents(), props.components);
  return React.createElement(React.Fragment, null, React.createElement(_components.p, null, "¿A primera vista, que notáis cuando veis esta gráfica?"), "\n", React.createElement(_components.p, null, "Yo noto un buen vacío. ¿Coincidimos? Entre 1990 y 2001 la enciclopedia Britannica cae en picado en sus ventas, mientras que el proyecto Wikipedia aún no ha empezado ¿Que falta entonces?"), "\n", React.createElement(_components.p, null, "Toda la web, los foros y los blogs, la imagen cae en el error de asociar Web 2.0 y Wikipedia con ese ", React.createElement(_components.em, null, "User creation"), " que llama. Pero realmente todo ese User creation ya existía, si no por qué iban a caer las enciclopedias clásicas ¿Por Encarta? Ya, claro…"), "\n", React.createElement(_components.p, null, "Esto es parte de esa negación de la Web distribuida anterior al 2.0 que hacen los que llegaron a ella cuando ya existía todo esto. Wikipedia puede ser útil para muchas cosas, adentrarte en un nuevo campo de conocimiento, encontrar recursos libres, etc. Pero no fue el causante de esta “revolución digital”, como tampoco lo fue ningún otro medio centralizado."), "\n", React.createElement(_components.p, null, "Fuentes: La imagen la he visto en el post ", React.createElement(_components.a, {
    href: "http://plmtwine.com/2014/08/22/how-to-reinvent-engineering-standard-and-references-books/"
  }, "How to reinvent engineering standard and references books?"), ", que aun que relacionado con el tema, no llega a asumir el concepto de Web distribuida en contraposición al 2.0."));
}
function MDXContent(props = {}) {
  const {wrapper: MDXLayout} = Object.assign({}, _provideComponents(), props.components);
  return MDXLayout ? React.createElement(MDXLayout, props, React.createElement(_createMdxContent, props)) : _createMdxContent(props);
}
export default MDXContent;
